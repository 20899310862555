<template>
    <div>
        <el-dialog
            title="添加车位检测设备"
            :visible.sync="dialogVisible"
            append-to-body
            width="36%"
            center=""
            :before-close="handleClose">
           
            <div class="eqname">
                <div class="eqona">
                    <span>设备名称：</span>
                    <el-select v-model="name" size="small" placeholder="请选择">
                        <el-option
                        v-for="item in options"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="tws">
                    <span>摄像头1管理车位数：</span>
                    <el-select v-model="f_camera_one" size="small" placeholder="请选择">
                        <el-option
                        v-for="item in 5"
                        :key="item"
                        :label="item"
                        :value="item">
                        </el-option>
                    </el-select>
                </div>
            </div>

            <div class="eqname">
                <div class="eqonaxlh">
                    <span>设备序列号：</span>
                    <el-input size="small" v-model="serial_number"></el-input>
                </div>
                <div class="tws">
                    <span>摄像头1管理车位数：</span>
                    <el-select v-model="camera_two" size="small" placeholder="请选择">
                        <el-option
                        v-for="item in 5"
                        :key="item"
                        :label="item"
                        :value="item">
                        </el-option>
                    </el-select>
                </div>
            </div>

            <div class="waicont">
                <span>外接显示屏显示内容：</span>
                <el-input size="small" placeholder="外接屏显示内容" v-model="f_serial_number"></el-input>
            </div>

            <div class="pakbut">
                <el-button size="small" type="success">获取路由设备</el-button>
            </div>
            
            <div class="eltag">
                <el-tag>路由1</el-tag>
                <el-tag>路由2</el-tag>
                <el-tag>路由3</el-tag>
            </div>
            <div class="contly">
                <div class="name">
                    <p>MAC地址 ：</p>
                    <p>SSID ：</p>
                    <p>RSSI ：</p>
                </div>
                <div class="msrconte">
                    <div class="mac">
                        <el-input size="small"  v-model="f_serial_number"></el-input>
                        <el-input size="small"  v-model="f_serial_number"></el-input>
                        <el-input size="small" v-model="f_serial_number"></el-input>
                    </div>
                    <div class="mac">
                        <el-input size="small"  v-model="f_serial_number"></el-input>
                        <el-input size="small"  v-model="f_serial_number"></el-input>
                        <el-input size="small" v-model="f_serial_number"></el-input>
                    </div>
                    <div class="mac">
                        <el-input size="small"  v-model="f_serial_number"></el-input>
                        <el-input size="small"  v-model="f_serial_number"></el-input>
                        <el-input size="small" v-model="f_serial_number"></el-input>
                    </div>
                </div>
            </div>

            <div class="eltag">
                <el-tag>路由4</el-tag>
                <el-tag>路由5</el-tag>
                <el-tag>路由6</el-tag>
            </div>
            <div class="contly">
                <div class="name">
                    <p>MAC地址 ：</p>
                    <p>SSID ：</p>
                    <p>RSSI ：</p>
                </div>
                <div class="msrconte">
                    <div class="mac">
                        <el-input size="small"  v-model="f_serial_number"></el-input>
                        <el-input size="small"  v-model="f_serial_number"></el-input>
                        <el-input size="small" v-model="f_serial_number"></el-input>
                    </div>
                    <div class="mac">
                        <el-input size="small"  v-model="f_serial_number"></el-input>
                        <el-input size="small"  v-model="f_serial_number"></el-input>
                        <el-input size="small" v-model="f_serial_number"></el-input>
                    </div>
                    <div class="mac">
                        <el-input size="small"  v-model="f_serial_number"></el-input>
                        <el-input size="small"  v-model="f_serial_number"></el-input>
                        <el-input size="small" v-model="f_serial_number"></el-input>
                    </div>
                </div>
            </div>

            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="handshow">确 定</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
export default {
    props:[
        'options'
    ],
    data(){
        return{
            dialogVisible:false,
            serial_number:'',//设备序列号
            f_camera_one:'',//摄像头1管理车位数
            camera_two:'',//摄像头2管理车牌数
            name:'',
            f_serial_number:'',
            UserInfo:JSON.parse(window.localStorage.getItem('user')),
            id:'',
        }
    },
    mounted(){

    },
    methods:{
        // 获取单挑数据
        getonepack(id){
            this.id = id
            let data={
                method:'co.parking.device.get',
                id:id
            }
            this.$serve(data).then(res=>{
                if(res.data.code==0){
                    let data = res.data.data
                    
                    this.name = data.name
                    this.serial_number= data.serial_number
                    this.camera_two = data.camera_two
                    this.f_camera_one = data.camera_one
                }
            })
        },
        handleClose(){
            this.dialogVisible= false
        },
        handshow(){
            let data={
                method:'co.parking.device.update',
                agent_id:this.UserInfo.agent_id,
                camera_one:this.f_camera_one,
                camera_two:this.camera_two,
                id:this.id?this.id:this.name
            }
            if(this.id){
                this.$serve(data).then(res=>{
                    if(res.data.code==0){
                        this.$message({
                            message: "修改成功!",
                            type: "success",
                        });
                        this.dialogVisible =false
                        this.$parent.getpark()
                        this.id=""
                    }
                })
            }else{
                 this.$serve(data).then(res=>{
                    if(res.data.code==0){
                        this.$message({
                            message: "添加成功!",
                            type: "success",
                        });
                        this.dialogVisible =false
                        this.$parent.getpark()
                    }
                })
            }
           
        }   
    }
}
</script>

<style  scoped>
.eqname{
    display: flex;
    margin-bottom: 10px;
}
.eqona >>>.el-select{
    width: 150px;
    margin-right: 30px;
}
.eqonaxlh >>>.el-input{
    width: 135px;
}
.eqonaxlh{
    margin-right: 30px;
}
.tws >>> .el-select{
    width: 100px;
}
.waicont{
    display: flex;
}
.waicont span{
    display: block;
    width:200px;
    line-height: 32px;
}
.pakbut{
    text-align: center;
    margin-top: 20px;
}
.eltag{
    margin-top: 10px;
    display: flex;
    justify-content: space-evenly;
}
.contly{
    display: flex;
    justify-content: space-evenly;
}
.name {
 width: 210px;
}
.name p{
   line-height: 28px;
    margin-top: 12px;
    text-align: right;
}
.msrconte{
    display: flex;
    
}
.msrconte >>> .el-input{
    width: 120px;
}
.mac >>> .el-input{
   margin-top: 10px;
    
}
</style>